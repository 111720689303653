import './contentf2.scss';
import contentImg from '../../../img/page-content-4/ct44.jpg';
import contentImgMob from '../../../img/page-content-4/ct44-mob.jpg';

const Contentf2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf2');
 
	contentElement.innerHTML = `
	<div class='contentf2__container'>
	<img class="contentf2__img-mob" width="432" height="210" src="${contentImgMob}">
	
	<div class="contentf2__body">
	<h2 class="contentf2__title">

	Риски инвестиций в компанию Apple

	</h2>
		<p class="contentf2__text">
		Известные финансисты рекомендуют создавать долгосрочные инвестиции в Эпл с целью создания практически безрискового инвестиционного портфеля. Акции этой компании характеризуются стабильностью, и регулярным ростом на финансовых рынках. Это делает их популярным активом как для краткосрочных, так и для долгосрочных вложений. Многие люди интересуются сколько можно заработать на акциях Apple. Однозначного ответа на этот вопрос нет. Все зависит от котировок на рынке Forex и фондовых биржах, а также от того, какая ключевая ставка будет назначена в США и будет ли коррекция программ выкупа активов, и от активности самого инвестора, времени, которое он готов уделять торговле. Хоть торговля в автоматическом режиме, но трейдеру нужно иногда следить за платформой.
		
		</p>
        <p class="contentf2__text">
		Незначительное понижение стоимости акций компании возможно, но полного провала в этом направлении не предвидится. Слишком много делает компания для того, чтобы развиваться и приносить пользу людям. Единственный риск заключается в самих акционерах. Если они поддадутся панике при малейшем понижении цены акций и продадут эти активы раньше времени. Терпеливые и уверенные в себе всегда выигрывают. Инвестиционный портфель, включающий в себя долгосрочные Apple, инвестиции не подвержен риску утраты денег. Главное – приобрести акции перед началом торгов, чтобы успеть принять в них участие. В этом вам тоже поможет уникальная торговая платформа, совершающая автоматические сделки. Акции компании Apple всегда востребованы среди инвесторов. Чтобы купить акции Apple физическому лицу, нужно заполнить специальную форму на сайте брокера.

		</p>
        <p class="contentf2__text">
		Если планируете приобрести активы на крупную сумму, приготовьте все необходимые документы для подтверждения источника вашего дохода. В этом случае брокеру потребуется время на проверку. На это может уйти до 5 дней. При необходимости можно продать акции Apple через мобильное приложение, а также через любую фондовую биржу. В настоящее время с продажи одной акции можно получить прибыль $0,24. Поэтому долгосрочные инвестиции значительно выгоднее. Если покупаете акции онлайн, не забывайте проверить продавца на мошенничество, чтобы не потерять свои средства.
			
		</p>

        
	</div>
		
    <img class="contentf2__img" width="480" height="1288" src="${contentImg}">
	
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf2;