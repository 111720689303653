import './content3.scss';
import contentImg3 from '../../../img/page-contant/content3/ct111.jpg';
import contentImg3mob from '../../../img/page-contant/content3/ct111-mob.jpg'

const Content3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content3');
 
	contentElement.innerHTML = `
	<div class='content3__container'>
	<img class="content3__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content3__img" width="464" height="464" src="${contentImg3}">
	
	<div class="content3__body">
	<h2 class="content3__title">
	Как заработать с компанией Apple
	</h2>
		<p class="content3__text">
		Компания Apple была основана в 1976 году Стивом Джобсом, Стивом Возняком и Рональдом Уэйном в калифорнийском городке Купертино. В 1980 году успешно провели IPO. С тех пор Apple инвестиции пользуются спросом среди акционеров. Акции компании котируются на фондовых биржах и рынке Forex. Она стабильно выплачивает дивиденды, а стоимость ее ценных бумаг постоянно растет. Созданное ими ПО автоматизирует все бизнес-процессы. Искусственный интеллект помогает продвигать инвестиции в Apple как прекрасный способ для повышения материального благополучия каждого человека.
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content3;