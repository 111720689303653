import './popup1.scss'; 
import popupImg from '../../../img/popup/pp1.jpg'; 

const company ='Chinadealis';
const Popup1 = (parentElement) => {

    if (document.querySelector('.popup1')) {
        return;
    }
    const popupElement = document.createElement('div');
    popupElement.classList.add('popup', 'popup1');

    popupElement.innerHTML = `
    <div class='popup1__overlay'>
        <div class='popup1__body'>
            <button class='popup1__close'>&times;</button>
            <img src='${popupImg}' class='popup1__img' alt='img/jpg' width='430' height='545'>
            <div class='popup1__form'>

            
                <h2 class='popup1__title'> Не упустите свой шанс!</h2>
                <p class='popup1__text'>
                Начните зарабатывать на инвестициях с ${company} прямо сейчас!
                </p>

                <form method="POST" action="#" autocomplete="off" class="lead-form consult-form" onsubmit="sendLead();" data-redirect="true" data-redirect-url="thank-you.html">
                    <input type="text" name="name" id="name" placeholder="Ваше Имя" required>
                    <input type="email" name="email" id="email" placeholder=" Email" required>
                    <input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Номер телефона" required>
                    <button class="consult-btn" type="submit">Начать зарабатывать</button>
                    <ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
                </form>
            </div>
        </div>
    </div>
    `;
   
    if (parentElement) {
        parentElement.appendChild(popupElement);
        document.body.classList.add('popup-active'); // Блокируем прокрутку

        // Добавляем классы для анимации
        setTimeout(() => {
            popupElement.classList.add('popup1--visible');
        }, 10); // Небольшая задержка, чтобы анимация сработала

        // Обработчики событий для закрытия попапа
        const closePopup = () => {
            popupElement.classList.remove('popup1--visible');
            setTimeout(() => {
                parentElement.removeChild(popupElement);
                document.body.classList.remove('popup-active'); // Разблокируем прокрутку
            }, 300); // Время, соответствующее продолжительности анимации
        };

        popupElement.querySelector('.popup1__close').addEventListener('click', closePopup);
        popupElement.querySelector('.popup1__overlay').addEventListener('click', (event) => {
            if (event.target === popupElement.querySelector('.popup1__overlay')) {
                closePopup();
            }
        });
    } else {
        console.error('Parent element not found');
    }
};


export default Popup1;