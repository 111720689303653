import './contentt4.scss';
import contentImg from '../../../img/page-content-3/ct444/ct444.jpg';
import contentImgMob from '../../../img/page-content-3/ct444/ct444-mob.jpg';
import contentImg2 from '../../../img/page-content-3/ct444/ct444-2.jpg';
import contentImg2mob from '../../../img/page-content-3/ct444/ct444-mob-2.jpg';
import contentImg3 from '../../../img/page-content-3/ct444/ct444-3.jpg';
import contentImg3mob from '../../../img/page-content-3/ct444/ct444-mob-3.jpg';

const Contentt4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt4');
 
	contentElement.innerHTML = `
	<div class='contentt4__container'>
	<img class="contentt4__img-mob" width="432" height="210" src="${contentImgMob}">
	<img class="contentt4__img" width="1180" height="530" src="${contentImg}">
	
	<div class="contentt4__body">
	<h2 class="contentt4__title">

	Можно ли обучаться со смартфона?

	</h2>
		<p class="contentt4__text">
		Для того чтобы пройти обучение, не обязательно заходить на сайт с ПК. Информация доступна также для владельцев смартфонов. Пользователи могут знакомиться с курсом о бизнесе на товарах из Китая в любое время в удобном для них месте. Если говорить о том как наладить поставки из Китая, то обучение поможет понять весь этот процесс. Пользователь получит такие знания как понимание специфики китайского рынка, включая его размеры, динамику и тенденции, что необходимо для оценки потенциала различных ниш и категорий товаров, выявления трендов навыки поиска и оценки поставщиков на популярных платформах, включающие в себя проверку репутации поставщиков.  <br>
Также пользователь получит драгоценные навыки ведения переговоров с китайскими партнерами, что критично для успешного бизнеса, узнает о разных видах доставки, логистике и таможне (оформление документов, разбор распространенных ошибок при импорте товаров). У него будет понимание правовых аспектов ведения бизнеса и как продавать товары из Китая (включает в себя законодательные требования к импортируемым товарам, контракты с китайскими поставщиками). Самостоятельно разобраться с тем с чего начать перепродажу товаров из Китая довольно сложно. Обучение бизнесу с Китаем предоставляет предпринимателям и бизнесменам ряд значительных преимуществ, которые могут значительно повысить их шансы на успех. </p>
	</div>
		

	
	</div>
	
	<div class='contentt2__container'>
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	Обязательно ли вкладывать деньги?

	</h2>
		<p class="contentt2__text">
		Информации о том как начать бизнес с Китаем есть много. И можно встретить упоминание о перепродаже, которую еще называют дропшиппинг. Эта бизнес-модель работает так - пользователь выбирает оптовых поставщиков на китайских площадках и заключает с ними договор, поставщик отправляет изображения товара пользователю, человек размещает на маркетплейсе или в социальных сетях фото, но уже со своей ценой.</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImg3mob}">
	
	</div>
		

	<img class="contentt2__img" width="560" height="514" src="${contentImg3}">
	</div>
	<div class="contentt4__container">
	<p class="contentt4__text"> Дальше покупатель связывается с пользователем и оплачивает товар, а продавец дает поставщику заказ, оплачивая ранее оговоренную сумму. Китайский поставщик по адресу в заказе отправляет товар, заработок от наценки остается у пользователя. Это обучение – инвестиция в знания и навыки, которые позволят эффективно организовать и развивать бизнес, в том числе и без денежных вложений. Есть множество бизнес идей из Китая, и при правильном подходе они приносят высокий доход.</p>
	</div>

	<div class='contentt2__container'>
	<img class="contentt2__img" width="560" height="514" src="${contentImg2}">
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	Бизнес без вложений

	</h2>
		<p class="contentt2__text">
		Во время обучения у каждого пользователя есть уникальная возможность узнать как начать бизнес с Китаем с нуля. Многие компании реализуют часть продукции через дропшиппинг, и их количество постоянно растет. Дропшиппинг — это модель бизнеса, при которой продавец не хранит товары на складе, а закупает их у поставщика только после получения заказа от покупателя. Это позволяет начать бизнес с минимальными затратами и рисками. Из преимуществ этой бизнес-модели можно выделить отсутствие необходимости вкладывать деньги, хранить товары, можно быстро тестировать новые ниши и продукты. Также дропшиппер имеет возможность предложить широкий ассортимент товаров.</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImg2mob}">
	
	</div>
		

	
	</div>
	`;
 
	return contentElement;
 };
 
 export default Contentt4;