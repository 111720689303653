import './contentt3.scss';
import contentImg3 from '../../../img/page-content-3/ct333/ct333.jpg';
import contentImg3mob from '../../../img/page-content-3/ct333/ct333-mob.jpg'

const Contentt3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt3');
 
	contentElement.innerHTML = `
	<div class='contentt3__container'>
	<img class="contentt3__img-mob" width="432" height="210" src="${contentImg3mob}">
	
	
	<div class="contentt3__body">
	<h2 class="contentt3__title">

	Перспективы развития компании как акционерного общества

	</h2>
		<p class="contentt3__text">
		Полностью автоматизированная система позволяет инвесторам зарабатывать на Apple без вложения личного времени и сил. Платформа сама проводит качественный анализ и создает выгодные сделки. Инвестор легко наращивает свой капитал и может подключить сложный процент для еще большей прибыли. Программное обеспечение не подвержено эмоциональным всплескам, поэтому работает без надрыва и волнений. Это позволяет принимать мгновенные решения и создавать рискованные сделки с положительным результатом. К преимуществам компании можно отнести то, что ею разрабатываются стратегии по внедрению в работу искусственного интеллекта, осуществляется объединение программного и аппаратного обеспечения, внедряются уникальные чипы для защиты информации.
		</p>

	</div>
		
	<img class="contentt3__img" width="1180" height="530" src="${contentImg3}">
	
	</div>

	<div class="contentt3__container">
	<h2 class="contentt3__title">

	Почему выгодно инвестировать в Apple
	</h2>
		<p class="contentt3__text">
		В дальнейшем компания планирует развивать AR технологии, выпустить собственный электрокар, создать чипы для беспроводной связи и др. Таким образом, акции компании и дальше будут расти в цене. Самое время купить акции Apple физическим лицам. Воспользуйтесь этим предложением прямо сейчас, чтобы никогда не жалеть об упущенном шансе создать личный капитал и поправить свое материальное положение. Программа позволяет выгодно торговать на фондовых биржах и получать стабильную прибыль. При этом инвестор не тратит время на настройку и сопровождение работы. Торговать акциями выгодно на торговых площадках у известных брокеров.

		</p>

		<p class="contentt3__text">
		Можно получать прибыль от роста стоимости активов при их продаже или от полученных дивидендов. Размер профита зависит от текущей обстановки в компании и ее доходности. Поэтому прямые инвестиции в Apple – это надежный вклад в будущее вашей семьи. Торговая платформа работает 24/7, поэтому не упустит ни одной сделки в течение суток. Результат ее работы моментально отражается в личном кабинете инвестора, а также на его балансе. Вывести деньги можно на криптокошелек, а в некоторых случаях сразу на банковскую карту. Полученную прибыль можно реинвестировать или использовать по своему усмотрению для закрытия текущих проблем и потребностей.
		
		</p>


		<h2 class="contentt3__title">

		Преимущества акций компании Apple

	</h2>
		<p class="contentt3__text">
		
		Громкое имя компании плюс ее отличная репутация – это одна из гарантий, что она не соскамится в ближайшее время и в любом случае будет получать прибыль. Основные преимущества таких вложений - высокая ликвидность акций, стабильная история развития и выплат акционерам, надежная платформа с отличной репутацией. Компания производит качественную продукцию, которая пользуется спросом, улучшает жизнь людей и облегчает их жизнь и труд. Зарабатывать на Apple легко и приятно. Ведь продукция компании создана для людей и для закрытия их потребностей. Акции компании обязательно должны быть в каждом инвестиционном портфеле как самый надежный способ сохранения и приумножения средств. Инвесторы, которые купили акции в 2014 году, получили от них доход, в 10 раз превышающий первоначальные затраты.
		</p>

	</div>
	
	
	`;
 
	return contentElement;
 };
 
 export default Contentt3;