import './contentf1.scss';
import contentImg from '../../../img/page-content-4/ct4.jpg';
import contentImgMob from '../../../img/page-content-4/ct4-mob.jpg';

const Contentf1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf1');
 
	contentElement.innerHTML = `
	<div class='contentf1__container'>
	
	<div class="contentf1__body">
	<h2 class="contentf1__title">

Как быстро можно получить доход

	</h2>
		<p class="contentf1__text">
			Для успешного ведения дропшиппинг-бизнеса необходимы тщательное планирование, эффективная маркетинговая стратегия и высокий уровень обслуживания клиентов, а также знания о том как перепродавать товары из Китая. Для того чтобы получить доход, нужно узнать все нюансы того как открыть бизнес с Китаем. Обучение не занимает много времени. Благодаря низкой стоимости рабочей силы и масштабным производственным мощностям, товары, выпускаемые на китайских фабриках стоят недорого, что позволяет быстро найти сбыт на продукцию. Он имеет хорошо развитую инфраструктуру, что облегчает логистику и транспортировку товаров.
		</p>
        <p class="contentf1__text">
		Современные порты, дороги и железнодорожные сети обеспечивают быстрые и эффективные поставки, сокращая время доставки продукции до конечного потребителя. Страна активно инвестирует в научные исследования и разработки, что делает ее лидером в таких областях, как электроника, информационные технологии и возобновляемая энергетика. Сотрудничество с китайскими компаниями открывает доступ к передовым технологиям и инновациям, что может существенно повысить конкурентоспособность бизнеса. Обучение предоставляет доступ к практическим кейсам и примерам успешного бизнеса, что помогает избежать распространенных ошибок и применить проверенные стратегии. Оно позволяет понять как построить бизнес с Китаем. Что получает в итоге пользователь - опыт успешного предпринимателя, практические рекомендации по запуску и масштабированию бизнеса, обмен опытом и идеями бизнеса с Китаем, возможности для сотрудничества и партнерства, поддержку и консультации от профессионалов.

		</p>
        <p class="contentf1__text">

		Сейчас много предпринимателей заказывают товар у оптовиков и продают его по своей цене, используя соцсети или специальные площадки. При этом они не вкладывают в это ни копейки. Дропшиппинг стал хорошей возможностью зарабатывать достойные деньги без вложений. Заработок на перепродаже товаров из Поднебесной – один из популярных способов ведения бизнеса благодаря низким ценам на продукцию и широкому ассортименту товаров. Вот что нужно, чтобы наладить поставки и начать прибыльный бизнес с Китаем с нуля - зарегистрироваться на сайте, оставить контактные данные, дождаться звонка специалиста.
			
		</p>

        
	</div>
		
    <img class="contentf1__img" width="1180" height="650" src="${contentImg}">
	<img class="contentf1__img-mob" width="432" height="210" src="${contentImgMob}">
	
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf1;