import './contents2.scss';
import contentImg3 from '../../../img/page-contant-2/content2-2/ct22.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-2/ct22-mob.jpg'

const Contents2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents2');
 
	contentElement.innerHTML = `
	<div class='contents2__container'>
	<img class="contents2__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="contents2__img" width="1180" height="400" src="${contentImg3}">
	
	<div class="contents2__body">
	<h2 class="contents2__title">
Почему нужно обучение для заработка на китайских товарах
	</h2>
		<p class="contents2__text">
			Перепродажа товаров – прибыльный бизнес, требующий внимательного подхода к их выбору, поиску поставщиков. В этом процессе не менее важна логистика и коммуникация с клиентами. Таможенное оформление – обязательный этап при ввозе товаров из-за границы, для которого требуется предоставить инвойс, упаковочный лист, сертификаты соответствия. Для успешной продажи товаров необходимо разработать стратегию маркетинга и продаж, организовать продвижение вещей через рекламу в социальных сетях, сотрудничество с блогерами. Для того, чтобы знать, на какие товары есть спрос в вашем регионе или стране, необходимо изучить информацию и рейтинги наиболее покупаемых товаров. Это поможет быть в курсе последних событий и выбирать востребованные товары, которые будут легко и быстро продаваться. Важно заранее ознакомиться с тем, какие пошлины и налоги установила таможня, что будут применяться к товару. Это учитывается в бизнес-плане. Хотя все кажется сложно, но обучение бизнесу с Китаем поможет разобраться со всеми нюансами.

		</p>
	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents2;