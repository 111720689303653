import './contents1.scss';
import contentImg2 from '../../../img/page-contant-2/content2-1/ct2.jpg';
import contentImg2mob from '../../../img/page-contant-2/content2-1/ct2-mob.jpg'

const Contents1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents1');
 
	contentElement.innerHTML = `
	<div class='contents1__container'>
	<img class="contents1__img-mob" width="432" height="210" src="${contentImg2mob}">

	
	<div class="contents1__body">
	<h2 class="contents1__title">

	Особенности торговли с ИИ

	</h2>
		<p class="contents1__text">
		Все торговые задачи будет выполнять платформа и искусственный интеллект. То есть, вам не нужно проводить анализ рынков, знакомиться с экономическими новостями, разрабатывать стратегии инвестирования, сравнивать цены, мониторить изменения. Все это вместо вас будет делать искусственный интеллект на площадке с новейшим софтом. Программа сама заметит, когда начнет расти цена на акции, чтобы можно было их продать с наибольшей выгодой. <br>
Программа позволяет совершать быстрые сделки, получать мгновенный доход, делать высокоточные прогнозы, заключать прибыльные торговые сделки без участия акционеров, обеспечивать полную безопасность данных пользователей.  диверсифицировать инвестиционный портфель, минимизировать риски. Инвесторы никогда не будут в убытках. Они смогут значительно приумножить средства, вложенные в компанию с помощью прямых инвестиций Apple.
	</p>

	</div>
		<img class="contents1__img" width="580" height="650" src="${contentImg2}">

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents1;