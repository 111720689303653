import './content4.scss';
import contentImg3 from '../../../img/page-contant/content4/ct1111.jpg';
import contentImg3mob from '../../../img/page-contant/content4/ct1111-mob.jpg'

const Content4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content4');
 
	contentElement.innerHTML = `
	<div class='content4__container'>
	<img class="content4__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content4__img" width="1180" height="310" src="${contentImg3}">
	
	<div class="content4__body">
	<h2 class="content4__title">

Почему инвесторы выбирают эту платформу

	</h2>
		<p class="content4__text">
			На сайте Volkswagen инвестиции пользователям представлен инновационный терминал, для автоматической торговли. Он уникален тем, что для трейдинга не нужно самому заниматься анализом, выбирать стратегии, открывать и закрывать сделки. ИИ все делает сам, исключая такие эмоции, как паника, жадность. Однако есть и другие особенности работы с терминалом. Площадка позволяет торговать множеством инструментов с высокой ликвидностью, составлять инвестиционный портфель из нескольких разных активов. Ее терминал простой в использовании и имеет автоматический режим торговли, благодаря чему на нем могут торговать профессионалы и новички.
		
		</p>
	<p class="content4__text">
		При автоматической торговле платформа с помощью искусственного интеллекта анализирует ситуацию на рынках и фиксирует все изменения, что позволяет мгновенно реагировать на колебания цен. Инвесторы могут заключать сделки на терминале на долгий и короткий срок даже при автоматическом трейдинге. Диверсификация портфеля, что позволяет правильно распределить активы, снижая риски убытков. Для защиты личных данных и финансовых средств используются инновационные технологии. Площадка часто обновляется, в нее добавляются новые функции.
	
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content4;